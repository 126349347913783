<template>
  <div class="container">
    <BreadCrumb :breadcrumbs="breadcrumbs" />
    <Alert :class="alertClass" v-show="alertContent" @closeAlert="alertContent = ''">{{alertContent}}</Alert>
    <div class="mb-3 row">
      <label class="col-1 col-form-label">Retailer</label>
      <div class="col-2">
        <select v-model="form.type" class="form-select">
          <option v-for="(item, key) in retailerMap" :key="key" :value="key">{{item}}</option>
        </select>
      </div>
    </div>
    <div class="mb-3 row">
      <label class="col-1 col-form-label">Product URL</label>
      <div class="col-6">
        <textarea
            v-model="form.productId"
            class="form-control"
            rows="3"
        ></textarea>
      </div>
    </div>
    <div class="mb-3 row">
      <label class="col-1 col-form-label">Price</label>
      <div class="col-2">
            <input type="text" v-model="form.price" class="form-control"/>
      </div>
    </div>
    <div class="mb-3 row">
      <label class="col-1 col-form-label">Notification</label>
      <div class="col-2">
        <select v-model="form.notification" class="form-select">
            <option value=1>On</option>
            <option value=0>Off</option>
        </select>
      </div>
    </div>
    <div class="mb-3 row">
      <label class="col-1 col-form-label">Status</label>
      <div class="col-2">
        <select v-model="form.status" class="form-select">
            <option value=1>Active</option>
            <option value=0>Inactive</option>
        </select>
      </div>
    </div>
    <div class="mb-3 row">
      <div class="col-1 col-form-label">
        <button type="button" class="btn btn-primary" @click="formSubmit">Save Changes</button>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios"

export default {
  name: "NotificationEdit",
  data: function () {
    return {
      form: {
        id: this.$route.query.id || ""
      },
      breadcrumbs: [{ name: "Notification", link: "/notification" }, { name: (this.$route.query.id ? "Update" : "New") + " Notification" }],
      submitted: false,
      alertContent: "",
      alertClass: "alert-danger",
      retailerMap: {1: "Amazon", 2: "Best Buy", 3: "Target"}
    };
  },
  mounted() {
      if (this.form.id) {
         this.getObject();
      }
  },
  methods: {
    formSubmit() {
      if (this.submitted) {
        return;
      }
      this.submitted = true;
      axios.post("/notification", this.form)
        .then(() => {
          this.submitted = false;
          this.alertContent = "Notification has been saved successfully!";
          this.alertClass = "alert-success";
        })
        .catch((error) => {
          this.alertContent = error.response ? error.response.data : error;
          this.alertClass = "alert-danger";
          this.submitted = false;
        });
    },
    getObject() {
      axios
      .get("/notification/" + this.form.id)
      .then((response) => {
        this.submitted = false;
        this.form = response.data;
      })
      .catch((error) => {
        this.submitted = false;
        this.alertContent = error.response ? error.response.data : error;
        this.alertClass = "alert-danger";
      });
    }
  },
};
</script>
